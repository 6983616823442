import React, { ReactElement, ReactNode, useState, SyntheticEvent } from 'react';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import '../input/Input.scss';

export interface TextareaProps
  extends Omit<ErrorProps, 'children'>,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'className' | 'ref' | 'name' | 'children'> {
  ref?: React.Ref<HTMLTextAreaElement>;
  showCharacterCount?: boolean;
  className?: string;
  description?: ReactNode;
}

const defaultProps = {
  className: '',
  showCharacterCount: false,
};

export const Textarea = React.forwardRef(
  (
    { name, showCharacterCount, description, className, ...shared }: TextareaProps,
    ref: React.Ref<HTMLTextAreaElement>
  ): ReactElement => {
    const { maxLength } = shared;
    const [characterCount, setCharacterCount] = useState(
      shared?.defaultValue?.toString().length || shared?.value?.toString.length
    );

    const keyUp = (event: SyntheticEvent): void => {
      const target = event.target as HTMLInputElement;
      setCharacterCount(target.value.length);
    };

    const classNameConcat = `uikit_input_container ${className}`;
    return (
      <ErrorWrapper name={name} className={classNameConcat} {...shared}>
        <span className="uikit_input_description">{description}</span>
        {showCharacterCount ? (
          <>
            <textarea name={name} {...shared} ref={ref} onKeyUp={keyUp}>
              {shared.value}
            </textarea>
            <div className="textarea-character_count">
              {shared.defaultValue ? (
                <>
                  {characterCount} / {maxLength}
                </>
              ) : (
                <>
                  {characterCount || 0} / {maxLength}
                </>
              )}
            </div>
          </>
        ) : (
          <textarea name={name} {...shared} ref={ref}>
            {shared.value}
          </textarea>
        )}
      </ErrorWrapper>
    );
  }
);

Textarea.defaultProps = defaultProps;

export default Textarea;
