import React, { ReactElement, useRef, useState, useCallback, useEffect } from 'react';
import { SelectProps } from './interface';
import ErrorWrapper, { ErrorProps } from '../ErrorWrapper';
import Icon from '../icon/Icon';
import './Select.scss';

interface CombinedProps extends SelectProps, Omit<ErrorProps, 'children'> {}

// 1.8.1 version of select that works for Techscout....
export const TSelect = React.forwardRef(
  (
    {
      label = '',
      defaultValue = '',
      name = 'notset',
      className = '',
      onChange,
      children,
      tabIndex = 0,
      ...shared
    }: CombinedProps,
    nref: React.Ref<HTMLInputElement>
  ): ReactElement => {
    const [selectedValue, setSelectedValue] = useState<string | number | string[] | undefined>(
      shared.value || defaultValue != null ? defaultValue : null || '' // default value could be a number 0 which is falsy (0 is false)
    );
    const [selectedLabel, setSelectedLabel] = useState<React.ReactNode>('Select Item...');
    const [isOpen, setIsOpen] = useState(false);
    const wrapRef = useRef<HTMLDivElement>(null);
    let allLables: { value: string | number | string[] | undefined; label: React.ReactElement<HTMLElement> }[];

    const toggleOpen = (set = !isOpen): void => {
      setIsOpen(set);
    };

    useEffect(() => {
      // TODO: fix this...
      // @ts-ignore
      allLables = React.Children.map(children, child => {
        return { value: child.props.value, label: child.props.children };
      });
      if (selectedValue !== null) {
        const theLabel = allLables.find(obj => obj.value === selectedValue);
        setSelectedLabel(theLabel?.label || 'Select Item...');
      }
    }, [children]);

    useEffect(() => {
      function handleClickOutside(event: Event): void {
        if (wrapRef.current && !wrapRef.current.contains(event.target as Node)) {
          toggleOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);

      return (): void => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [wrapRef]);

    const selectValue = useCallback(
      (newSelectedValue: string | number | string[] | undefined): void => {
        setSelectedValue(newSelectedValue);
        const theLabel = allLables.find(obj => obj.value === newSelectedValue);
        setSelectedLabel(theLabel?.label || 'Select Item...');
        if (onChange) onChange({ value: newSelectedValue, name });
        toggleOpen(false);
      },
      [name]
    );

    let classNameConcat = `uikit_select_container ${className}`;
    if (isOpen) {
      classNameConcat = `${classNameConcat} open`;
    }

    return (
      <div ref={wrapRef}>
        <ErrorWrapper name={name} label={label} className={classNameConcat} {...shared}>
          <div className="uikit_select_wrapper">
            <div
              className="uikit_select_selected"
              role="button"
              tabIndex={tabIndex}
              onKeyDown={(): void => toggleOpen()}
              onClick={(): void => toggleOpen()}
            >
              <span>{selectedLabel}</span>
              <span className="uikit_arrow_icon">
                <Icon styled="none" icon={`${isOpen ? 'fal fa-angle-up' : 'fal fa-angle-down'}`} />
              </span>
            </div>
            {isOpen && (
              <ul className="uikit_select_list">
                {React.Children.map(children, child => {
                  return React.cloneElement(child, { selectValue, selectedValue });
                })}
              </ul>
            )}
            <input name={name} readOnly ref={nref} value={selectedValue} className="uikit_select_hidden_input" />
          </div>
        </ErrorWrapper>
      </div>
    );
  }
);
export default TSelect;
